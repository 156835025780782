<template lang="pug">
lot-card-search(
  from-card
  hide-detail-link
  :adaptive="!isAuth"
  :lot="details"
  @show-create-contract="openCreateContractDialog"
)

ClientOnly
  create-contract-dialog(
    v-model:show="showCreateContract"
    :cost="createContractPayload.cost"
    :responsible="createContractPayload.responsiblePerson"
    :lot-id="createContractPayload.lotId"
    @on:hide="onHideDialog"
  )
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from "vue";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import LotCardSearch from "@/components/cards/LotCardSearch.vue";
import CreateContractDialog from "@/components/mainStreamPage/lotItem/dialogs/CreateContractDialog.vue";

import type { ManagerI } from "@/stores/auth/UserInterface";
import type { CostItemI } from "@/stores/manuals/ManualsInterface";
import type { LotDetailInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "LotDetailCard",
  components: {
    LotCardSearch,
    CreateContractDialog,
  },
  props: {
    details: {
      type: Object as PropType<LotDetailInterface>,
      default: () => ({}),
    },
  },

  setup() {

    const showCreateContract = ref(false);
    const createContractPayload: any = ref(getDefaultPayload());

    const { isAuth } = useUserAccess();

    function getDefaultPayload() {
      return {
        responsiblePerson: null,
        cost: null,
        lotId: null,
      }
    }

    function onHideDialog() {
      createContractPayload.value = getDefaultPayload();
    }

    function openCreateContractDialog(payload: { responsiblePerson: ManagerI, cost: CostItemI, lotId: number }) {
      createContractPayload.value = payload;
      showCreateContract.value = true;
    }

    return {
      isAuth,
      onHideDialog,
      showCreateContract,
      createContractPayload,
      openCreateContractDialog,
    };
  },
});
</script>

<style scoped lang="scss">
</style>

<template lang="pug">
settings-panel.multi-table-panel
  ui-loader(v-if="loader" label="Загружаем список лотов")
  no-data(v-else-if="error" text="Тендер не является многолотовым или произошла ошибка при загрузке данных")
  .multi-lot-table(v-else)
    .header.grid-layout
      .td.full-view №
      .td Номер
      .td Название
      .td.purchase-label Объект закупки
      .td.full-view Цена

    .table-row.grid-layout(
      v-for="lot of data"
      :key="lot.lotId"
    )
      .td.full-view {{ lot.lotNumber }}

      .td.flex
        lot-link(:id="lot.lotId" :number="lot.govRuId")
        ui-tag(v-if="lotId === lot.lotId" name="Текущая страница" size="small" type="success")
        span.flex.row.adaptive-view
          span.adaptive-label Лот:
          span №{{ lot.lotNumber }}
        span.flex.row.adaptive-view
          span.adaptive-label Цена:
          span
            template(v-if="lot?.cost?.original") {{ costFormatter(lot.cost.original.currency.alpha).format(lot.cost.original.cost) }}
            template(v-else) Цена неизвестна
        span.flex.row.adaptive-view.secondary(v-if="lot?.cost?.converted")
          span(style="color: var(--main-color-black); margin-left: 8px;") ~ {{ costFormatter(lot.cost.converted.currency.alpha).format(lot.cost.converted.cost) }}

      .contents
        .td.flex
          span.adaptive-label Название:
          span {{ lot.lotTitle }}

        .td.purchase-info.flex
          span.adaptive-label Объект закупки:
          span {{ lot.purchaseObjects }}

      .td.full-view
        span
          template(v-if="lot?.cost?.original") {{ costFormatter(lot.cost.original.currency.alpha).format(lot.cost.original.cost) }}
          template(v-else) Цена неизвестна
        span(v-if="lot?.cost?.converted" style="color: var(--main-color-black); margin-left: 8px;") ~ {{ costFormatter(lot.cost.converted.currency.alpha).format(lot.cost.converted.cost) }}

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { costFormatter } from "~/utils/formatter/costFormatter";

import type { LotItemInterface } from "~/stores/search/LotItemInterface";
import LotLink from "~/components/ui/links/LotLink.vue";
import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import UiTag from "~/components/ui/tag/UiTag.vue";
import NoData from "~/components/ui/table/NoData.vue";
import UiLoader from "~/components/ui/loader/UiLoader.vue";

export default defineComponent({
  name: "MultipleLots",
  methods: {
    costFormatter,
  },
  components: {
    UiLoader,
    NoData,
    UiTag,
    LotLink,
    SettingsPanel,
  },
  props: {
    lotId: {
      type: Number,
      default: 0,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<LotItemInterface[]>,
      default: () => [],
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';

.multi-lot-table {
  padding: 0;

  .grid-layout {
    display: grid;
    grid-template-columns: 52px 210px repeat(2, minmax(120px, 1fr)) 194px;
  }
}

.contents {
  display: contents;
}

.flex {
  display: flex;
  flex-flow: column;
  gap: 4px;

  &.row {
    flex-flow: row;
  }
}

.adaptive-view {
  display: none;
}

.adaptive-label {
  display: none;

  font-size: var(--detail-table--size--info);
  line-height: var(--detail-table--height--info);
  color: var(--secondary-text-color);
}

.secondary {
  color: var(--secondary-text-color);
}

@media (max-width: 1080px) {
  .adaptive-view {
    display: flex;
  }
  .full-view {
    display: none;
  }
  .multi-lot-table {
    .grid-layout {
      grid-template-columns: 210px repeat(2, minmax(120px, 1fr));
    }
  }
}

@media (max-width: 860px) {
  .adaptive-label {
    display: flex;
  }

  .multi-lot-table {
    --detail-table--size--default: 13px;
    --detail-table--size--info: 13px;

    --detail-table--height--default: 17px;
    --detail-table--height--info: 17px;

    .grid-layout {
      grid-template-columns: 210px minmax(120px, 1fr);
      padding: 8px 0;
    }
    .td {
      padding: 4px 8px;
    }
  }

  .purchase-label {
    display: none;
  }

  .contents {
    display: flex;
    flex-flow: column;
  }
}

@media (max-width: 620px) {
  .multi-lot-table {
    .grid-layout {
      display: flex;
      flex-flow: column;
    }
    .header {
      display: none;
    }
  }
  .multi-table-panel {
    padding: 8px;
  }
}
</style>

<template lang="pug">
ui-loader(v-if="loader" label="Загружаем похожие лоты")
no-data(v-else-if="error" text="Похожих лотов не найдено")
.similar-lots(v-else)
  lot-item-search(
    v-for="item of data"
    :key="item.lotId"
    :lot="item"
    :adaptive="!isAuth"
    :simple-card="true"
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import type { LotItemInterface } from "~/stores/search/LotItemInterface";

import UiLoader from "~/components/ui/loader/UiLoader.vue";
import LotItemSearch from "~/components/cards/LotCardSearch.vue";
import NoData from "~/components/ui/table/NoData.vue";

export default defineComponent({
  name: "SimilarLots",
  components: {
    NoData,
    UiLoader,
    LotItemSearch,
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array as PropType<LotItemInterface[]>,
      default: () => [],
    },
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
.similar-lots {
  display: flex;
  flex-flow: column;
  gap: 12px;
}
</style>
